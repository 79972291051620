<template>
  <div class="info">
    <span class="left" @click="goTo">

    </span>
    <div class="tabs" v-show="showTab">
      <span
        v-for="item in tab"
        :key="item"
        @click="tabCli(item)"
        :class="{ active: ac === item }"
        >{{ item }}</span
      >
    </div>
   <detail v-if="ac==='详情模式'"></detail>
   <mobile v-else @close="close"></mobile>
  </div>
</template>

<script>
import detail from './detail.vue'
import mobile from './mobile.vue'
export default {
  components: {detail,mobile},
  data() {
    return {
      ac: "详情模式",
      tab: ["详情模式", "地图模式"],
      showTab:true
    }
  },
  computed: {},
  watch: {},
  methods: {
    goTo(){
      this.$router.push({
        path:'/index'
      })
    },
    tabCli(item) {
      this.ac = item
    },
    close(e){
      this.showTab=e
    }
  
  },
  created() {
   if(this.$route.query.tab){
    this.ac = '地图模式'
   }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.info {
  padding-bottom: 100px;
  background: #f5f4f4;
  position: relative;
  .left{
    position: absolute;
    top: 0px;
    left: 10px;
    width: 40px;
    height: 40px;
    background: url(../assets/img/hleft.png) no-repeat center;
    background-size: cover;
    z-index: 999;
    cursor: pointer;
  }
  .tabs {
    display: flex;
    height: 43px;
    margin-bottom: 5px;
    position: relative;
    z-index: 99;
    background: #fff;
    span {
      display: inline-block;
      flex: 1;
      text-align: center;
      height: 100%;
      line-height: 43px;
      font-size: 14px;
      color: #666666;
      border-bottom: 2px solid #fff;
    }
    .active {
      color: #e02323;
      border-bottom:2px solid #e02323;
    }
  }
}

</style>
