<template>
  <div class="info">
    <div class="banner">
      <van-swipe :autoplay="3000" :show-indicators="false" @change="changeS">
        <van-swipe-item v-for="(image, index) in info1.img" :key="index">
          <img v-lazy="image" />
          <div class="jb">
            <p>{{ info1.name }}</p>
            <span><i></i>{{ sIndex }}/{{ info1.img.length }}</span>
          </div>
         
        </van-swipe-item>
      </van-swipe>
      <div class="txt">
        <div class="desc">{{ info1.desc }}</div>
        <p>
          容纳人数：<i>{{ info1.num }}</i>
        </p>
        <p>
          地址：<i>{{ info1.address }}</i>
        </p>
      </div>
    </div>
    <div class="fwxx">
      <h3>服务信息</h3>
      <p class="address">
        服务阵地：<i>{{ info.name }}</i>
      </p>
      <p class="time">
        服务时间：<i>{{ info.time }}</i>
      </p>
      <p class="user">
        联系人：<i>{{ info.user }}</i>
      </p>
      <p class="phone">
        联系电话：<i>{{ info.mobile }}</i>
      </p>
      <div class="desc">{{ info.desc }}</div>
    </div>
    <div class="fwmore">
      <h3>服务站更多空间展示</h3>
      <div class="more">
        <div
          class="item"
          v-for="(item, index) in info.serve"
          @click="preview(index)"
          :key="item.name"
        >
          <img :src="item.icon" alt="" srcset="" />
          <!-- <p>{{ item.name }}</p> -->
        </div>
      </div>
    </div>
    <div class="open-phone" @click="show = true">
      <span>电话咨询<i></i></span>
    </div>
    <van-popup
      v-model="show"
      closeable
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="pop">
        <h5>拨打电话</h5>
        <p>{{ info.mobile }}</p>
        <a :href="tel">立即拨打</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { data } from "@/utils/data"
import { data1 } from "@/utils/data1"
import { ImagePreview } from "vant"
export default {
  components: {},
  data() {
    return {
      show: false,
      info: {},
      info1: {},
      images: [
        require("../assets/img/bg.png"),
        require("../assets/img/bg.png"),
      ],
      sIndex: 1,
      tel:''
    }
  },
  computed: {},
  watch: {},
  methods: {
    preview(index) {
     let arr =[]
      this.info.serve.forEach(e=>{
        arr.push(e.icon)
      })
      ImagePreview({
        images: arr,
        startPosition: index,
      })
    },
    tabCli(item) {
      this.ac = item
    },
    changeS(e) {
      this.sIndex = e + 1
    },
  },
  created() {
    let name = this.$route.query.name
    data.forEach((e) => {
      if (e.name === name) {
        this.info = e
        this.tel = "tel:"+this.info.mobile
      }
    })
    data1.forEach((e) => {
      if (e.service === name) {
        this.info1 = e
      }
    })
    if (!this.info.name) {
      this.$notify({ type: "warning", message: "未找到信息" })
    }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.info {
  padding-bottom: 100px;
  background: #f5f4f4;
}
.pop {
  h5 {
    height: 50px;
    line-height: 50px;
    font-weight: 700;
    font-size: 14px;
    color: #333333;
    text-align: center;
    border-bottom: 1px solid #f5f5f7;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    margin: 16px 0 28px 0;
    text-align: center;
  }
  a {
    display: block;
    width: 80%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    background: #cc0500;
    border-radius: 8px 8px 8px 8px;
  }
}
.serve-t {
  display: flex;
  align-items: center;
  padding: 0 12px;
  .s-item {
    height: 64px;
    width: 64px;
    img {
      margin: 6px 0 0 0;
    }
  }
  .p {
    flex: 1;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    i {
      font-weight: 700;
    }
  }
}

.banner {
  margin-bottom: 6px;
  position: relative;
  font-size: 0;
  .jb{
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
    height: 46px;

    background: url(../assets/img/jb.png) no-repeat center;
    background-size: cover;
  }
  .txt {
    padding: 12px;
    box-sizing: border-box;
    background: #fff;
    .desc {
      font-size: 14px;
      color: #666666;
      line-height: 16px;
    }
    p {
      margin-top: 6px;
      font-weight: 500;
      font-size: 12px;
      color: #666666;
      i {
        color: #333;
      }
    }
  }
  
}
.fwxx {
  padding: 12px;
  background: #fff;
  h3 {
    font-weight: 700;
    font-size: 14px;
    color: #333333;
    line-height: 16px;
  }
  p {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
    margin-top: 8px;
    padding-left: 20px;
    i {
      color: #333;
    }
  }
  .desc {
    margin: 10px 0;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
  }
  .address {
    background: url(../assets/img/zd.png) no-repeat left center;
    background-size: 14px;
  }
  .time {
    background: url(../assets/img/time.png) no-repeat left center;
    background-size: 14px;
  }
  .user {
    background: url(../assets/img/user.png) no-repeat left center;
    background-size: 14px;
  }
  .phone {
    background: url(../assets/img/phone.png) no-repeat left center;
    background-size: 14px;
  }
}
.fwmore {
  margin-top: 6px;
  padding: 12px;
  background: #fff;
  h3 {
    font-weight: 700;
    font-size: 14px;
    color: #333333;
    line-height: 16px;
  }
  .more {
    font-size: 0;
    margin-top: 12px;
    .item {
      display: inline-block;
      width: 50%;
      height: 112px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      margin-bottom: 8px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      p {
        height: 22px;
        line-height: 22px;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        font-size: 10px;
        padding-left: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 0 6px 6px;
      }
    }
    .item:nth-child(2n + 1) {
      padding-right: 4px;
      p {
        right: 4px;
      }
    }
    .item:nth-child(2n) {
      padding-left: 4px;
      p {
        left: 4px;
      }
    }
  }
}
.open-phone {
  position: fixed;
  bottom: 0;
  width: 100%;
  // height: 72px;
  padding: 12px 0;
  background: #fff;
  z-index: 9;
  span {
    display: inline-block;
    width: 80%;
    text-align: center;

    margin-left: 10%;
    background: #e0201f;
    border-radius: 8px 8px 8px 8px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    i {
      display: inline-block;
      width: 26px;
      height: 26px;
      margin-left: 5px;
      background: url(../assets/img/bsphone.png) no-repeat center;
      background-size: cover;
      vertical-align: middle;
    }
  }
}
.van-swipe-item {
  position: relative;
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  p {
    position: absolute;
    bottom: 8px;
    left: 12px;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
  }
  span {
    display: inline-block;
    width: 45px;
    height: 17px;
    background: rgba(255, 255, 255, 0.3);
    text-align: center;
    border-radius: 16px 16px 16px 16px;
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 8px;
    right: 12px;
    i {
      display: inline-block;
      margin-right: 2px;
      width: 10px;
      height: 8px;
      background: url(../assets/img/photo.png) no-repeat center;
      background-size: cover;
    }
  }
}
/deep/ .van-swipe__indicators {
  bottom: 30px;
}
/deep/ .van-swipe__indicator {
  background: #fff;
  opacity: 1;
}
/deep/ .van-swipe__indicator--active {
  background: #cc0500;
}
</style>
